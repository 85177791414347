import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { HttpErrorResponse } from '@angular/common/http';
import { ProfileApiService, ProfileDto, UpdatePasswordDto, UpdateProfileDto } from '@advn/web/webapi/data-access';
import { computed, inject } from '@angular/core';
import { mergeMap, pipe, switchMap, tap } from 'rxjs';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { DialogService } from '@advn/shared/web/ui/dialog';
import { AlertDefaultComponent, AlertService } from '@advn/shared/web/ui/alert';

type ProfileState = {
   profile: ProfileDto | null; // which Profile record has been selected
   referrer: string | undefined;
   loading: boolean;
   error: HttpErrorResponse | null;
};

const initialState: ProfileState = {
   profile: null,
   referrer: undefined,
   loading: false,
   error: null,
};

const selectId = (profile: ProfileDto) => profile._id;

export const ProfileStore = signalStore(
   { providedIn: 'root' },
   withState(initialState),
   withComputed(({ profile, referrer, loading, error }) => ({
      isLoggedIn: computed(() => profile()),
      submitted: computed(() =>
         loading() == false ? (error() == null ? referrer() : error()) : null,
      ),
   })),
   withMethods(
      (
         store,
         profileApiService = inject(ProfileApiService),
         dialogService = inject(DialogService),
         alertService = inject(AlertService),
      ) => ({
         loggedOut() {
            patchState(store, {
               error: null,
               profile: null,
               referrer: undefined,
               loading: false,
            });
         },
         getProfile: rxMethod<void>(
            pipe(
               tap(() => patchState(store, { loading: true })),
               mergeMap(() =>
                  profileApiService.profileControllerGetProfile().pipe(
                     tapResponse({
                        next: (response) => {
                           console.log(
                              '[ProfileStore] profileControllerGetProfile',
                              response,
                           );
                           patchState(store, {
                              profile: response,
                              error: null,
                              loading: false,
                           });
                        },
                        error: (error: HttpErrorResponse) => {
                           patchState(store, {
                              profile: {
                                 _id: '1',
                                 email: 'email',
                                 name: 'name',
                              } as ProfileDto,
                              error,
                              loading: false,
                           });

                           alertService.open(AlertDefaultComponent, {
                              message: error.message,
                              title: 'Error',
                              variant: 'error',
                              width: '431px',
                           });
                        },
                     }),
                  ),
               ),
            ),
         ),
         updateProfile: rxMethod<{ referrer: string; data: UpdateProfileDto }>(
            pipe(
               tap(() => patchState(store, { loading: true })),
               mergeMap((request) => {
                  return profileApiService
                     .profileControllerUpdateProfile(request.data)
                     .pipe(
                        tapResponse({
                           next: (response) => {
                              console.log(
                                 '[ProfileStore] profileControllerUpdateProfile',
                                 response,
                              );
                              patchState(store, {
                                 profile: { ...store.profile(), ...response },
                                 error: null,
                                 referrer: request.referrer,
                                 loading: false,
                              });
                           },
                           error: (error: HttpErrorResponse) => {
                              patchState(store, { error, loading: false });

                              alertService.open(AlertDefaultComponent, {
                                 message: error.message,
                                 variant: 'error',
                                 width: '431px',
                              });
                           },
                           // finalize: () => patchState(store, { referrer: request.referrer, loading: false }),
                        }),
                     );
               }),
            ),
         ),
         updatePassword: rxMethod<{
            referrer: string;
            data: UpdatePasswordDto;
         }>(
            pipe(
               tap(() => patchState(store, { loading: true })),
               mergeMap((request) => {
                  return profileApiService
                     .profileControllerUpdatePassword(request.data)
                     .pipe(
                        tapResponse({
                           next: (response) => {
                              console.log(
                                 '[ProfileStore] profileControllerUpdatePassword',
                                 response,
                              );
                              patchState(store, {
                                 error: null,
                                 referrer: request.referrer,
                                 loading: false,
                              });
                           },
                           error: (error: HttpErrorResponse) => {
                              patchState(store, { error, loading: false });

                              alertService.open(AlertDefaultComponent, {
                                 message: error.message,
                                 variant: 'error',
                                 width: '431px',
                              });
                           },
                           // finalize: () => patchState(store, { referrer: request.referrer, loading: false }),
                        }),
                     );
               }),
            ),
         ),
      }),
   ),
);
